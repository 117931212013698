
import { EChartsOption } from 'echarts';
import { GridOption, XAXisOption, YAXisOption, SeriesOption } from 'echarts/types/dist/shared';
import * as React from 'react';
import { useSelector } from 'react-redux';
import FieldDetailBody from '../../../../../components/FieldDetailPaper/FieldDetailBody';
import FieldDetailHeader from '../../../../../components/FieldDetailPaper/FieldDetailHeader';
import FieldDetailPaper from '../../../../../components/FieldDetailPaper/FieldDetailPaper';
import { ReactECharts } from '../../../../../components/ReactEcharts/ReactEcharts';
import useSoilMoistureProfileContext from '../../../../../hooks/ContextHooks/useSoilMoistureProfileContext';
import { getUnitName, valueFormatter, valueRounder } from '../../../../../utils/numericConversions';

import styles from './style.module.css';
import moment from 'moment';
import HelpIconButton from '../HelpIconButton/HelpIconButton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MinimumMaximum } from '../../../../../utils/Types';

export interface ISoilMoistureProfileProps {
}

enum graphLegend {
  Moisture,
  MoistureWithDailyUsage,
  Temperature,
  ElectricalConductivity
}

//graph setup
type graphSetup = {
  grid: GridOption[],
  xAxis: XAXisOption[],
  yAxis: YAXisOption[],
  moistureSeries: any[],
  tempSeries: any[],
  electricalConductivitySeries: any[],
  dailyUsageSeries: any[],
  xAxisIndex: number[],
}

const color = ['#1A1989', '#FC282A', '#5A8B54', '#151515', '#FDAB3D', '#FDB5C3', '#85EF81', '#A1D1E0', '#f0f00c', '#bebebe', '#d3a6d3', '#910c0c'];

export default function SoilMoistureProfile(props: ISoilMoistureProfileProps) {

  const { numericSystem }: { numericSystem: string } = useSelector((state: any) => state.user);
  const temperatureLegendTitle = numericSystem === "M" ? "Temperature(\u00B0C)" : "Temperature(\u00B0F)";
  const tempSign = numericSystem === "M" ? "\u00B0C" : "\u00B0F";
  const { depth, minMaxMoisture, minMaxTemp, minMaxElectricalConductivity, SoilMoistureGraphSeries, loading } = useSoilMoistureProfileContext();
  const dailyUsageText = numericSystem === 'M' ? 'Daily Usage' : 'Daily Usage (inch/10)';
  const dailyUsageMap = SoilMoistureGraphSeries.dailyUsageMap;
  const length = numericSystem === "M" ? "mm" : "In";
  const [graphLegendType, setGraphLegendType] = useState<graphLegend>(graphLegend.Moisture);
  const [legendTitles, setLegendTitles] = useState<string[]>();
  
  useEffect(() => {
    // check if Electrical Conductivity data available
    if (SoilMoistureGraphSeries?.GraphData && SoilMoistureGraphSeries.GraphData["depth1ElectricalConductivity"]?.data?.filter((_data: any) => Array.isArray(_data) && _data.length > 0 && _data[1] !== null && _data[1] !== undefined).length > 0)
      setLegendTitles(['Moisture', dailyUsageText, temperatureLegendTitle, "Electrical Conductivity"])
    else
      setLegendTitles(['Moisture', dailyUsageText, temperatureLegendTitle])
  }, [SoilMoistureGraphSeries, dailyUsageText, temperatureLegendTitle, setLegendTitles])

  const handleLegendChange = (params: any) => {
    if (params.selected.Moisture || params.selected[dailyUsageText]) {
      setGraphLegendType(graphLegend.Moisture)

      if (params.selected[dailyUsageText]) {
        setGraphLegendType(graphLegend.MoistureWithDailyUsage)
      }
    }
    else if (params.selected[temperatureLegendTitle]) {
      setGraphLegendType(graphLegend.Temperature)
    }
    else if (params.selected["Electrical Conductivity"]) {
      setGraphLegendType(graphLegend.ElectricalConductivity)
    }
  }
  
  const setUp: graphSetup = useMemo(() => {
    const grid: GridOption[] = [];
    const xAxis: XAXisOption[] = [];
    const yAxis: YAXisOption[] = [];
    const moistureSeries: SeriesOption[] = [];
    const tempSeries: SeriesOption[] = [];
    const electricalConductivitySeries: SeriesOption[] = [];
    const dailyUsageSeries: SeriesOption[] = [];
    const xAxisIndex = [];
    let minMax: MinimumMaximum;

    switch (graphLegendType) {
      case graphLegend.MoistureWithDailyUsage:
      case graphLegend.Moisture: {
        minMax = minMaxMoisture;
        break;
      }
      case graphLegend.Temperature: {
        minMax = minMaxTemp;
        break;
      }
      case graphLegend.ElectricalConductivity: {
        minMax = minMaxElectricalConductivity;
        break;
      }
    }

    for (let i = 1; i <= depth.length; i++) {
      const index = i - 1;
      //grid
      if (depth.length === 12) {
        grid.push({
          //calculation to make it manually align with the rootzone graph
          top: `${(i * 7) + 3 / i}%`,
          height: '5%',
          left: 70,
          right: 70
        });
      }
      else {
        grid.push({
          top: `${i * 10}%`,
          height: '9%',
          left: 70,
          right: 70
        });
      }
      //xAxis
      if (i !== depth.length) {
        xAxis.push({
          type: 'time',
          show: false,
          gridIndex: index,
          axisPointer: {
            label: {
              show: false,
            }
          }
        })
      }
      else {
        xAxis.push({
          type: 'time',
          show: true,
          gridIndex: index,
          axisPointer: {
            label: {
              formatter: function (param: any) {
                const dateTimeFormat = numericSystem === "M" ? "DD/MM/YYYY HH:mm:ss" : "MM/DD/YYYY HH:mm:ss"
                return moment(param.value).format(dateTimeFormat);
              }
            }
          },
        })
      }
      //xAxisIndex
      xAxisIndex.push(index);
      //yAxis
      yAxis.push({
        name: depth[index],
        type: 'value',
        scale: true,
        gridIndex: index,
        nameRotate: 0,
        nameLocation: 'middle',
        min: minMax.min,
        max: minMax.max,
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false
        }
      })

      if (SoilMoistureGraphSeries?.GraphData) {
        const SoilMoistureGraphSeriesData = SoilMoistureGraphSeries?.GraphData[`depth${i}`]?.data;
        //moistureSeries
        moistureSeries.push({
          name: 'Moisture',
          type: 'line',
          data: SoilMoistureGraphSeriesData ?? [],
          xAxisIndex: index,
          yAxisIndex: index,
          symbol: 'circle',
          symbolSize: 1,
          emphasis: {
            disabled: true,
          },
          sampling: 'lttb',
          lineStyle: {
            color: color[index]
          },
          itemStyle: {
            color: color[index],
          },
          label: {
            show: true,
            position: 'right',
            formatter: (param: any) => {
              if (SoilMoistureGraphSeriesData && (SoilMoistureGraphSeriesData ?? []).length - 1 === param.dataIndex) {
                return valueRounder(param.value[1], numericSystem === 'M' ? 0 : 2) + ' ' + length ?? '';
              }
              else return '';
            }
          },
        },);
        const electricalConductivitySeriesData = SoilMoistureGraphSeries?.GraphData[`depth${i}ElectricalConductivity`]?.data;
        if (legendTitles && legendTitles.includes("Electrical Conductivity"))
        {
          electricalConductivitySeries.push({
            name: 'Electrical Conductivity',
            type: 'line',
            data: electricalConductivitySeriesData ?? [],
            xAxisIndex: index,
            yAxisIndex: index,
            symbol: 'circle',
            symbolSize: 1,
            emphasis: {
              disabled: true,
            },
            sampling: 'lttb',
            lineStyle: {
              color: color[index]
            },
            itemStyle: {
              color: color[index]
            },
            label: {
              show: true,
              position: 'right',
              formatter: (param: any) => {
                if (SoilMoistureGraphSeries?.GraphData[`depth${i}ElectricalConductivity`]?.data && (SoilMoistureGraphSeries?.GraphData[`depth${i}ElectricalConductivity`]?.data ?? []).length - 1 === param.dataIndex) {
                  return valueRounder(param.value[1], 3) + ' dS/m' ?? '';
                }
                else return '';
              }
            }
          });
        }

        //tempSeries
        tempSeries.push({
          name: temperatureLegendTitle,
          type: 'line',
          data: SoilMoistureGraphSeries?.GraphData[`depth${i}Temp`]?.data ?? [],
          xAxisIndex: index,
          yAxisIndex: index,
          symbol: 'circle',
          symbolSize: 1,
          emphasis: {
            disabled: true,
          },
          sampling: 'lttb',
          lineStyle: {
            color: color[index]
          },
          itemStyle: {
            color: color[index],

          },
          label: {
            show: true,
            position: 'right',
            formatter: (param: any) => {
              if (SoilMoistureGraphSeries?.GraphData[`depth${i}Temp`]?.data && (SoilMoistureGraphSeries?.GraphData[`depth${i}Temp`]?.data ?? []).length - 1 === param.dataIndex) {
                return valueRounder(param.value[1], 0) + ' ' + tempSign ?? '';
              }
              else return '';
            }
          },
        },);

        // Right label code
        const dailyUsageSeriesRightLabelData = SoilMoistureGraphSeriesData ?? [];
        dailyUsageSeries.push({
          name: dailyUsageText,
          type: 'line',
          symbolSize: 0,
          data: dailyUsageSeriesRightLabelData && dailyUsageSeriesRightLabelData.length > 0 && [dailyUsageSeriesRightLabelData[dailyUsageSeriesRightLabelData.length - 1]],
          xAxisIndex: index,
          yAxisIndex: index,
          silent: true,
          emphasis: {
            disabled: true,
          },
          lineStyle: {
            color: "#FFFFFF00"
          },
          tooltip: { show: false },
          label: {
            show: true,
            position: 'right',
            formatter: (params: any) => valueRounder(params.data[1] as number, numericSystem === 'M' ? 0 : 2) + " " + getUnitName(numericSystem, 'mm')
          },
        },);

        dailyUsageSeries.push({
          name: dailyUsageText,
          type: 'line',
          symbol: 'circle',
          symbolSize: 1,
          legendHoverLink: false,
          emphasis: {
            disabled: true,
          },
          data: SoilMoistureGraphSeriesData ?? [],
          xAxisIndex: index,
          yAxisIndex: index,
          lineStyle: {
            color: color[index]
          },
          itemStyle: {
            color: color[index],
          },
          label:
          {
            show: true,
            position: 'top',
            formatter: (param: any) => {
              const dailyUsageMap = SoilMoistureGraphSeries.dailyUsageMap.get(`depth${i}`);
              if (param.data && param.data.length === 2) {
                return dailyUsageMap.get(param.data[0]) !== undefined ? dailyUsageMap.get(param.data[0]) : ''
              }
              return ''
            }
          },

        },);
      }
    }
    return { grid, xAxis, xAxisIndex, yAxis, moistureSeries, tempSeries, electricalConductivitySeries, dailyUsageSeries }
  }, [depth, graphLegendType, SoilMoistureGraphSeries, numericSystem, temperatureLegendTitle, dailyUsageText, length, tempSign, legendTitles])

  const option: EChartsOption = {
    animation: false,
    showSymbol: false,
    grid: setUp.grid,
    legend: {
      data: legendTitles,
      selectedMode: 'single',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: (param: any) => {
        let res = `<div style="margin-bottom:2px;">` + moment(param[0].axisValueLabel).format(numericSystem === "M" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY HH:mm") + "</div>";
        res += graphLegendType === graphLegend.MoistureWithDailyUsage
          ? `<div style="margin-bottom:2px;display:flex;flex-direction:row;justify-content:space-between">
              <span style="margin-left:20px;margin-right:0px;font-weight:bold;flex:1;text-align:left;">${numericSystem === "M" ? 'cm' : 'In'}</span>
              <span style="margin-right:0px;margin-left:50px;font-weight:bold;flex:1;text-align:right">Moisture</span>
              <span style="font-weight:bold;margin-left:20px;flex:1;text-align:right">Daily Usage</span>
            </div>`
          : '';
        for (var i = 0; i < param.length; i++) {
          if (param[i].data && param[i].data.length === 2) {
            let roundingPlaces;
            let unit;
            switch (graphLegendType) {
              case graphLegend.MoistureWithDailyUsage:
              case graphLegend.Moisture: {
                roundingPlaces = numericSystem === 'M' ? 0 : 2;
                unit = numericSystem === "M" ? "mm" : "In";
                break;
              }
              case graphLegend.Temperature: {
                roundingPlaces = 0;
                unit = numericSystem === "M" ? "\u00B0C" : "\u00B0F";
                break;
              }
              case graphLegend.ElectricalConductivity: {
                roundingPlaces = 3;
                unit = "dS/m";
                break;
              }
            }

            // skip over one
            if (graphLegendType === graphLegend.MoistureWithDailyUsage && param.length > 8)
              i++;

            //search for the right component index in param
            const sortedParam = param.sort((a: any, b: any) => {
              if (!a.hasOwnProperty('componentIndex') && !b.hasOwnProperty('componentIndex')) return 0;
              return a['componentIndex'] - b['componentIndex'];
            });

            if (graphLegendType === graphLegend.MoistureWithDailyUsage) {
              const dailyUsageMapDepth = dailyUsageMap.get(`depth${i + 1}`)
              res +=
                `<div>
                  <span style="display:flex;flex-direction:row;justify-content:space-around">
                    <span style="flex:1">
                      <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${color[i]};"></span>
                        ${valueFormatter(numericSystem, (i + 1) * 10, 'cm', true, 0)}:
                      </span>
                      <span style="flex:0.4;text-align:right">
                        ${(sortedParam[i].value[1] !== null ? valueRounder(sortedParam[i].value[1], roundingPlaces) + ' ' : '-') + unit}
                      </span>
                      <span style="flex:1;margin-right:auto;text-align:right">
                          ${dailyUsageMapDepth.get(sortedParam[i].value[0]) !== undefined ? valueRounder(dailyUsageMapDepth.get(sortedParam[i].value[0]), 2) + ' ' + (numericSystem === "M" ? "mm" : "In/10") : '-'}&nbsp 
                      </span>
                    </span>
                  </div>`;
              continue;
            }
            res +=
              `<div>
                <span style="display:flex;flex-direction:row;justify-content:space-between">
                  <span>
                    <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${color[i]};"></span>
                      ${valueFormatter(numericSystem, (i + 1) * 10, 'cm', true, 0)}:&nbsp &nbsp &nbsp &nbsp
                  </span>
                  <span>
                    ${(sortedParam[i].value[1] !== null ? valueRounder(sortedParam[i].value[1], roundingPlaces) + ' ' : '-') + unit}&nbsp 
                  </span>
                </span>
              </div>`;
          }
        }
        return res;
      },
    },
    dataZoom: {
      type: 'slider',
      xAxisIndex: setUp.xAxisIndex,
    },
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all'
        }
      ]
    },
    xAxis: setUp.xAxis,
    yAxis: setUp.yAxis,
    series: [...setUp.moistureSeries, ...setUp.tempSeries, ...setUp.dailyUsageSeries, ...setUp.electricalConductivitySeries],
  };
  return (
    <FieldDetailPaper width='49%' id="SoilMoistureProfileGraph">
      <FieldDetailHeader backgroundColor='#D9D7D780'>
        <div className={styles.SoilMoistureProfileHeader}>
          Soil Moisture Profile
          <HelpIconButton
            content={[
              {
                title: "Soil Moisture Profile",
                content: "Soil moisture profile refers to the distribution of water in the soil profile, which is the vertical section of soil from the ground surface down to the maximum rooting depth of plants. In the graph it shows the soil moisture profile in 10cm or 4-inch layers. The soil moisture profile can vary depending on a variety of factors such as rainfall, irrigation, evaporation, and plant water use."
              },
              {
                title: "Daily Usage",
                content: "Refers to the Volumetric water use expressed in mm or inches, extracted from the soil in the last 24hrs updated every hour."
              },
            ]}
          />
        </div>
      </FieldDetailHeader>
      <FieldDetailBody className={styles.fieldDetailBody} style={{ marginLeft: '0px', marginRight: '0px', paddingLeft: '10px', paddingRight: '10px' }} loading={loading}>
        <ReactECharts option={option} style={{ height: '630px', width: '95%' }} loading={loading} onLegendChange={handleLegendChange} />
      </FieldDetailBody>
    </FieldDetailPaper>
  );
}